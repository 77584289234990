import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, Auth, sendPasswordResetEmail, signInWithCredential, EmailAuthProvider } from 'firebase/auth';
import { getFirestore, collection, addDoc, getDocs, query, orderBy, limit, Firestore, doc, getDoc, setDoc, where, updateDoc, increment, runTransaction } from 'firebase/firestore';
import { getStorage, ref as storeRef, uploadBytes, getDownloadURL, FirebaseStorage } from 'firebase/storage';
import { ContentItem, WaitlistFormData, DraftLotteryVideo, NewContentItem } from '../types';

const firebaseConfig = {
  apiKey: "AIzaSyAA-KB4dTPhi4LaqblhHl4uYBCaFauJFPg",
  authDomain: "fantasyloser-f6991.firebaseapp.com",
  projectId: "fantasyloser-f6991",
  storageBucket: "fantasyloser-f6991.appspot.com",
  messagingSenderId: "240012027880",
  appId: "1:240012027880:web:be8a7e0a5d579c5340265d",
  measurementId: "G-4GGYREH9RV"
};

interface WaitlistData {
  fullName: string;
  email: string;
  fantasyLeaguesCount: string;
  commishLeaguesCount: string;
  yearsPlayed: string;
  interestReason: string;
  fantasyProfileUrl?: string;
}

export interface League {
  id?: string;
  name: string;
  commissionerId: string;
  commish: string;
  leagueOrigin: string;
  recentChamp: string;
  recentLoser: string;
  champsTrophy: string;
  losersTrophy: string;
  bestWin: string;
  worstWin: string;
  mostMovesAvg: string;
  leagueUrl: string;
  notableEvents: string;
  isPasswordProtected: boolean;
  password?: string;
  punishment?: {
    title: string;
    link: string;
    status: 'approved' | 'denied' | 'pending';
    selectedPunishment: string;
    customPunishment: string;
  };
  mostRecentLoser: {
    name: string;
    fantasyTeam: string;
    record: string;
    winPct: string;
    totalPoints: string;
    pointsRank: string;
    timesLoser: number;
    historicalWinPct: string;
    proofOfPunishment: string;
    imageUrl: string;
  };
  deadbeatTracker: Array<{
    player: string;
    team: string;
    amountOwed: number;
    datePaid: string;
    status: 'PAID' | 'DEADBEAT' | 'HALF DEADBEAT';
  }>;
  videoLibrary: string[];
  playerProfiles: string[];
}

const app = initializeApp(firebaseConfig);
export const auth: Auth = getAuth(app);
export const db: Firestore = getFirestore(app);
export const storage: FirebaseStorage = getStorage(app);

if (!storage) {
  console.error('Firebase Storage is not initialized properly');
}

export const loginUser = (email: string, password: string) => {
  return signInWithEmailAndPassword(auth, email, password);
};

const uploadVideo = async (file: File): Promise<string> => {
  const storageRef = storeRef(storage, `videos/${Date.now()}_${file.name}`);
  await uploadBytes(storageRef, file);
  return getDownloadURL(storageRef);
};

export const registerUser = (email: string, password: string) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const logoutUser = () => {
  return signOut(auth);
};

export const addToWaitlist = async (formData: WaitlistFormData) => {
  try {
    const docRef = await addDoc(collection(db, 'waitlist'), formData);
    console.log('Document written with ID: ', docRef.id);
    return docRef.id;
  } catch (e) {
    console.error('Error adding document: ', e);
    throw e;
  }
};


export const addContent = async (contentData: Omit<ContentItem, 'id'>): Promise<string> => {
  try {
    let imageUrl = contentData.image;

    // If contentData.image is a File object, upload it and get the URL
    if (contentData.image instanceof File) {
      const storageRef = storeRef(storage, `images/${Date.now()}_${contentData.image.name}`);
      const snapshot = await uploadBytes(storageRef, contentData.image);
      imageUrl = await getDownloadURL(snapshot.ref);
    }

    // Create a new object without the image property and without an id
    const { image, ...dataToSave } = contentData;

    // Add the document to Firestore
    const docRef = await addDoc(collection(db, 'content'), {
      ...dataToSave,
      image: imageUrl,
      createdAt: new Date(),
    });

    // Return the Firestore-generated document ID
    return docRef.id;
  } catch (error) {
    console.error('Error adding content:', error);
    throw error;
  }
};

export const getAllContent = async (): Promise<ContentItem[]> => {
  try {
    const querySnapshot = await getDocs(collection(db, 'content'));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as ContentItem));
  } catch (e) {
    console.error('Error getting all content: ', e);
    throw e;
  }
};

export const getRandomContent = async (count: number, extraForFiltering: boolean = false): Promise<ContentItem[]> => {
  try {
    const fetchCount = extraForFiltering ? count * 2 : count;
    const querySnapshot = await getDocs(query(collection(db, 'content'), orderBy('createdAt', 'desc'), limit(fetchCount)));
    const allItems = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as ContentItem));
    return allItems.sort(() => 0.5 - Math.random()).slice(0, count);
  } catch (e) {
    console.error('Error getting random content: ', e);
    throw e;
  }
};

export const uploadImage = async (image: File): Promise<string> => {
  const storageRef = storeRef(storage, `images/${Date.now()}_${image.name}`);
  await uploadBytes(storageRef, image);
  return getDownloadURL(storageRef);
};

export const getUserRole = async (userId: string): Promise<string | null> => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    return userDoc.data()?.role || null;
  } catch (e) {
    console.error('Error getting user role: ', e);
    throw e;
  }
};

export const setUserRole = async (userId: string, role: string): Promise<void> => {
  try {
    await setDoc(doc(db, 'users', userId), { role }, { merge: true });
  } catch (e) {
    console.error('Error setting user role: ', e);
    throw e;
  }
};

export const getContentByTag = async (tag: string): Promise<any[]> => {
  try {
    const q = query(collection(db, 'content'), where('tags', 'array-contains', tag));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (e) {
    console.error(`Error getting content with tag ${tag}: `, e);
    throw e;
  }
};

export const getDraftLotteryVideos = async (): Promise<DraftLotteryVideo[]> => {
  try {
    const querySnapshot = await getDocs(collection(db, 'draftLotteryVideos'));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as DraftLotteryVideo));
  } catch (e) {
    console.error('Error getting draft lottery videos: ', e);
    throw e;
  }
};

export const uploadDraftLotteryVideo = async (video: Omit<DraftLotteryVideo, 'id' | 'videoUrl' | 'createdAt'>, file: File): Promise<void> => {
  try {
    const videoUrl = await uploadVideo(file);

    await addDoc(collection(db, 'draftLotteryVideos'), {
      ...video,
      videoUrl,
      createdAt: new Date()
    });
  } catch (e) {
    console.error('Error uploading draft lottery video: ', e);
    throw e;
  }
};

export const submitFeedback = async (feedback: {
  type: 'punishment' | 'draft' | 'challenge';
  title: string;
  description: string;
  userId: string;
}) => {
  try {
    await addDoc(collection(db, 'feedback'), {
      ...feedback,
      createdAt: new Date()
    });
  } catch (e) {
    console.error('Error submitting feedback: ', e);
    throw e;
  }
};

export const getContentById = async (id: string): Promise<ContentItem | DraftLotteryVideo | null> => {
  try {
    // First, try to fetch from 'content' collection
    let docRef = doc(db, 'content', id);
    let docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() } as ContentItem;
    }

    // If not found, try 'draftLotteryVideos' collection
    docRef = doc(db, 'draftLotteryVideos', id);
    docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() } as DraftLotteryVideo;
    }

    // If not found in either collection, return null
    return null;
  } catch (e) {
    console.error('Error getting content by id: ', e);
    throw e;
  }
};

export const createLeague = async (leagueData: Omit<League, 'id'>): Promise<string> => {
  try {
    const docRef = await addDoc(collection(db, 'leagues'), {
      ...leagueData,
      createdAt: new Date()
    });
    return docRef.id;
  } catch (e) {
    console.error('Error creating league: ', e);
    throw e;
  }
};


export const getLeagueById = async (id: string): Promise<League | null> => {
  try {
    const docRef = doc(db, 'leagues', id);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const league = { id: docSnap.id, ...docSnap.data() } as League;
      // Remove the password field before returning the league data
      if (league.password) {
        delete league.password;
      }
      return league;
    } else {
      return null;
    }
  } catch (e) {
    console.error('Error getting league by id: ', e);
    throw e;
  }
};

export const updateLeague = async (id: string, leagueData: Partial<League>): Promise<void> => {
  try {
    const docRef = doc(db, 'leagues', id);
    await updateDoc(docRef, leagueData);
  } catch (e) {
    console.error('Error updating league: ', e);
    throw e;
  }
};


export const getLeaguesByCommissioner = async (commissionerId: string): Promise<League[]> => {
  try {
    const q = query(collection(db, 'leagues'), where('commissionerId', '==', commissionerId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
      const league = { id: doc.id, ...doc.data() } as League;
      // Remove the password field before returning the league data
      if (league.password) {
        delete league.password;
      }
      return league;
    });
  } catch (e) {
    console.error('Error getting leagues by commissioner: ', e);
    throw e;
  }
};


export const verifyLeaguePassword = async (leagueId: string, password: string): Promise<boolean> => {
  try {
    const docRef = doc(db, 'leagues', leagueId);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      const league = docSnap.data() as League;
      return league.isPasswordProtected ? league.password === password : true;
    } else {
      return false;
    }
  } catch (e) {
    console.error('Error verifying league password: ', e);
    throw e;
  }
};

export const getContentByType = async (contentType: string): Promise<any[]> => {
  try {
    const q = query(collection(db, 'content'), where('type', '==', contentType));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (e) {
    console.error(`Error getting content with type ${contentType}: `, e);
    throw e;
  }
};

export const resetPassword = (email: string) => {
  return sendPasswordResetEmail(auth, email);
};

export const voteContent = async (contentId: string, userId: string, voteType: 'up' | 'down' | null) => {
  const contentRef = doc(db, 'content', contentId);
  const votesRef = doc(db, 'contentVotes', contentId);

  try {
    await runTransaction(db, async (transaction) => {
      const votesDoc = await transaction.get(votesRef);
      
      if (!votesDoc.exists()) {
        // If the document doesn't exist, create it with initial values
        if (voteType) {
          transaction.set(votesRef, {
            upvotes: voteType === 'up' ? 1 : 0,
            downvotes: voteType === 'down' ? 1 : 0,
            userVotes: {
              [userId]: voteType
            }
          });
          transaction.update(contentRef, {
            [`${voteType}votes`]: increment(1),
          });
        }
      } else {
        // If the document exists, update it
        const currentVotes = votesDoc.data();
        const currentUserVote = currentVotes.userVotes[userId];

        if (currentUserVote === voteType) {
          // User is trying to vote the same way, so remove their vote
          transaction.update(votesRef, {
            [`${currentUserVote}votes`]: increment(-1),
            [`userVotes.${userId}`]: null,
          });
          transaction.update(contentRef, {
            [`${currentUserVote}votes`]: increment(-1),
          });
        } else {
          // User is changing their vote or voting for the first time
          if (currentUserVote) {
            // Remove the old vote
            transaction.update(votesRef, {
              [`${currentUserVote}votes`]: increment(-1),
            });
            transaction.update(contentRef, {
              [`${currentUserVote}votes`]: increment(-1),
            });
          }
          if (voteType) {
            // Add the new vote
            transaction.update(votesRef, {
              [`${voteType}votes`]: increment(1),
              [`userVotes.${userId}`]: voteType,
            });
            transaction.update(contentRef, {
              [`${voteType}votes`]: increment(1),
            });
          } else {
            // Just remove the user's vote
            transaction.update(votesRef, {
              [`userVotes.${userId}`]: null,
            });
          }
        }
      }
    });

    // Fetch and return the updated vote counts
    const updatedVotesDoc = await getDoc(votesRef);
    const updatedVotes = updatedVotesDoc.data() || { upvotes: 0, downvotes: 0, userVotes: {} };
    return {
      upvotes: updatedVotes.upvotes,
      downvotes: updatedVotes.downvotes,
      userVote: updatedVotes.userVotes[userId] || null,
    };
  } catch (error) {
    console.error('Error voting:', error);
    throw error;
  }
};
export const getContentVotes = async (contentId: string) => {
  try {
    const votesRef = doc(db, 'contentVotes', contentId);
    const votesDoc = await getDoc(votesRef);
    
    if (votesDoc.exists()) {
      const data = votesDoc.data();
      return {
        upvotes: data.upvotes || 0,
        downvotes: data.downvotes || 0,
        userVotes: data.userVotes || {}
      };
    } else {
      return {
        upvotes: 0,
        downvotes: 0,
        userVotes: {}
      };
    }
  } catch (error) {
    console.error('Error getting content votes:', error);
    throw error;
  }
};

// Create a secondary app
const secondaryApp = initializeApp(firebaseConfig, 'Secondary');
const secondaryAuth = getAuth(secondaryApp);
const db2 = getFirestore(secondaryApp);


export const createUser = async (email: string, password: string, role: 'user' | 'commissioner' | 'admin') => {
  try {
    // Create new user with secondary app
    const userCredential = await createUserWithEmailAndPassword(secondaryAuth, email, password);
    const user = userCredential.user;

    // Set user role in Firestore
    await setDoc(doc(db2, 'users', user.uid), {
      email: user.email,
      role: role
    });

    // Sign out from secondary app
    await signOut(secondaryAuth);

    // Return success
    return { success: true, uid: user.uid };
  } catch (error) {
    console.error('Error creating user:', error);
    throw error;
  }
};
